<div class="sidebar-component-header">
  <div class="component-title">
    Drive Pipeline
  </div>
  <div class="component-search-bar">
    <i class="pi pi-refresh"
      style="color: #367B88; margin-right: 10px; margin-top: 40x; font-size: 17px; cursor: pointer;"
      (click)="refreshInterviewDetails()">
    </i>
  </div>
</div>

<div>
  <p-accordion (onOpen)='onTabOpen($event)'>
    <ng-container *ngFor="let status of candidateStatusList.slice()">
      <p-accordionTab [header]="status">
        <div class="bulk-interview-assignment"
          style="display:flex; justify-content: end; margin-bottom: 10px; margin-left: 5px;">
          <p-button *ngIf="status == 'PROFILE_SEL' && this.userService.IsAdmin()" class="bulk-assignment-btn"
            label="Schedule Interview" (onClick)="displayPipelineActionDialog(status)">
          </p-button>
          <button style="margin-left: 5px;" type="button" pButton pRipple icon="pi pi-file-excel"
            (click)="exportExcel()" class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button>
          <br>
        </div>

        <p-table #candidates [columns]="cols" [value]="candidatesList" styleClass="p-datatable-gridlines"
          [tableStyle]="{ 'min-width': '30rem' }" [globalFilterFields]="['NAME', 'EMAIL', 'PHONE']">
          <ng-template pTemplate="caption">
            <div style="text-align: right">
              <span class="p-input-icon-right">
                <i class="pi pi-search" style="color: #367B88"></i>
                <input type="text" pInputText size="30" placeholder="Search"
                  (input)="candidates.filterGlobal($any($event.target).value, 'contains')" style="width:auto" />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr>
              <td *ngFor="let col of columns">
                <ng-container *ngIf="col.field === 'actions' && this.userService.IsAdmin()">
                  <div class="icon-container">
                    <i class="pi pi-step-forward-alt" style="margin-right: 15%;"
                      (click)="displayPipelineActionDialog(rowData.STATUS, rowData.EMAIL)"></i>
                    <i class="pi pi-comments" style="margin-right: 15%;" pTooltip="Add Comments"
                      (click)="displayaddComments( rowData.EMAIL, rowData.TYPE)"></i>
                    <i class="pi pi-eye" (click)="displayCandidateInfo(rowData.EMAIL)"></i>
                    <i class='custom-he-icon' pTooltip="Schedule Hacker Earth"
                      (click)="scheduleInterview(rowData.EMAIL)"
                      *ngIf="!rowData.SHOW_CANCEL && (rowData.STATUS == 'INT_1_SCHD' || rowData.STATUS=='INT_2_SCHD' || rowData.STATUS=='INT_M_SCHD' )"></i>
                    <i class="custom-he-icon-cancel" pTooltip="Cancel Hacker Earth" (click)="confirmCancelation($event,rowData)"
                      *ngIf="rowData.SHOW_CANCEL && (rowData.STATUS == 'INT_1_SCHD' || rowData.STATUS=='INT_2_SCHD' || rowData.STATUS=='INT_M_SCHD' )"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="col.field === 'serialNumber'">
                  {{ rowIndex + 1 }}
                </ng-container>
                <div class="icon-container" *ngIf="col.field === 'CURRENT_PANEL' && status != 'PROFILE_SEL'">
                  {{ rowData[col.field] }}
                  <i class="pi pi-info-circle" style="margin-left: 1.5rem;"
                    (click)="displayPanelInfo(rowData.CURRENT_PANEL)"></i>
                  <i class="pi pi-pencil" *ngIf="checkPanelUpdate(status)"
                    (click)="displayUpdatePanel(rowData.STATUS, rowData.EMAIL, rowData.CURRENT_PANEL)"></i>
                </div>
                <ng-container *ngIf="col.field === 'RESUME'" >
                  <ul [style]="{'padding-left':'10%'}">
                  <li>
                    <ng-container *ngIf="rowData.RESUME !== null">
                      <a style="color:blue" [href]="rowData.RESUME" target="_blank">Resume</a>
                    </ng-container>
                    <ng-container *ngIf="rowData.RESUME === null">
                      No Resume
                    </ng-container>
                  </li>
                  <li *ngIf="rowData.INT_1_SCHD_PANEL_HELINK && rowData.INT_1_SCHD_PANEL_HELINK!='' && (rowData.STATUS == 'INT_1_SCHD')"><a style="color:blue" [href]="rowData.INT_1_SCHD_PANEL_HELINK" target="_blank">Interview Link</a></li>
                  <li *ngIf="rowData.INT_2_SCHD_PANEL_HELINK && rowData.INT_2_SCHD_PANEL_HELINK!='' && (rowData.STATUS == 'INT_2_SCHD')"><a style="color:blue" [href]="rowData.INT_2_SCHD_PANEL_HELINK" target="_blank">Interview Link</a></li>
                  <li *ngIf="rowData.INT_M_SCHD_PANEL_HELINK && rowData.INT_M_SCHD_PANEL_HELINK!='' && (rowData.STATUS == 'INT_M_SCHD')"><a style="color:blue" [href]="rowData.INT_M_SCHD_PANEL_HELINK" target="_blank">Interview Link</a></li>

                </ul>
                </ng-container>
                <ng-container *ngIf="col.field === 'NAME' || col.field === 'EMAIL'">
                  <div class="copy-candidate-details">
                    <span style="margin-right: 20px;">{{ rowData[col.field] }} </span>
                    <i class="pi pi-copy" (click)="copyTextToClipboard(rowData[col.field])"></i>
                  </div>
                </ng-container>
                <ng-container *ngIf="!(['actions', 'RESUME',  'CURRENT_PANEL', 'NAME', 'EMAIL']).includes(col.field)">
                  {{ rowData[col.field] }}
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]='cols.length'>No records found.</td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </ng-container>
  </p-accordion>
</div>

<p-dialog header="Action" *ngIf="isDisplayPipelineAction" [(visible)]="isDisplayPipelineAction"
  [style]="{width: '30vw'}" [draggable]="false" [modal]="true">
  <div class="p-fluid">
    <form [formGroup]="pipelineActionForm" (ngSubmit)="onPipelineActionSubmit()">
      <div class="p-field">
        <p-dropdown formControlName="action" [options]="allowedActionlist" placeholder="Select action" appendTo="body"
          (onChange)="onActionSelect($event)">
        </p-dropdown>
      </div>

      <div class="p-field" *ngIf="isDisplayPanelList">
        <p-dropdown formControlName="panel" [options]="getPanelsByInterviewType()" [showClear]="true"
          placeholder="Select Panelist" [filter]="true" [filterBy]="'label'" appendTo="body">
        </p-dropdown>
      </div>

      <div class="p-field">
        <label for="comment">Comment</label>
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" id="comment" formControlName="comment"
          style="min-height: 36px; white-space: pre-wrap;" [maxlength]="textAreaLimit">
            </textarea>
        <div [ngStyle]="{color: 'red', 'margin-top': '5px'}"
          *ngIf="pipelineActionForm.controls['comment'].hasError('maxlength')">
          Maximum character limit reached.
        </div>
      </div>

      <div class="p-field">
        <button pButton type="submit" label="Submit" [disabled]="!pipelineActionForm.valid" [style]="{width: '5vw'}">
        </button>
      </div>
    </form>
  </div>

</p-dialog>

<p-dialog header="Candidate Details" *ngIf="isDisplayCandidateInfo" [(visible)]="isDisplayCandidateInfo"
  [style]="{ width: '60vw', 'margin-left': '0px' }" [draggable]="false" [resizable]="false" [modal]="true">
  <div header="Advanced Card" subheader="Card Subheader" style="width: '360px'; margin-left: '80px';">
    <p style="margin-bottom: 3px;"> <b>Name :</b> {{currentCandidateToDisplay.NAME}} </p>
    <p style="margin-bottom: 3px;"> <b>Phone :</b> {{currentCandidateToDisplay.PHONE}} </p>
    <p style="margin-bottom: 3px;"> <b>Email :</b> {{currentCandidateToDisplay.EMAIL}} </p>
    <p style="margin-bottom: 3px;"> <b>Resume : </b> <a [href]="currentCandidateToDisplay.RESUME" target="_blank">Resume
        link</a></p>
    <p style="margin-bottom: 3px;"> <b>Total Score :</b> {{currentCandidateToDisplay.TOTALSCORE}} </p>
    <p style="margin-bottom: 3px;" *ngIf="currentCandidateToDisplay.APTISCORE"> <b>Total Aptitude score :</b>
      {{currentCandidateToDisplay.APTISCORE}} </p>
      
      <p style="margin-bottom: 3px;" *ngIf="currentCandidateToDisplay.APTISCORE"> <b>Total Programming score :</b>
        {{currentCandidateToDisplay.PROGSCORE}} </p>
  </div>
  <p style="margin: 25px 0px 35px 0px;"> <b>Comments:</b> </p>

  <p-timeline [value]="currentCandidateToDisplay.COMMENTS!">
    <ng-template pTemplate="opposite" let-comment>
      <small class="p-text-secondary">{{comment.COMMENT_DATE}} by {{comment.COMMENT_BY}}</small>
    </ng-template>
    <ng-template pTemplate="content" let-comment>
      <pre
        style="margin-bottom: 15px; border-bottom: 1px solid #ddd; "><div style="white-space: pre-wrap;">{{comment.COMMENT_MESSAGE}}</div></pre>
    </ng-template>
  </p-timeline>
</p-dialog>

<p-dialog header="Panel Details" *ngIf="isDisplayPanelInfo" [(visible)]="isDisplayPanelInfo"
  [style]="{ width: '30vw', 'margin-left': '0px' }" [draggable]="false" [resizable]="false">
  <div header="Advanced Card" subheader="Card Subheader" style="width: '20%'; margin-left: '80px';">
    <p style="margin-bottom: 3px;"> <b>Panel ID : {{ currentPanelToDisplay.ID }} </b> </p>
    <p style="margin-bottom: 3px;"> <b>First Panelist : {{ currentPanelToDisplay.FIRST_PANELIST_NAME }} </b> </p>
    <p style="margin-bottom: 3px;" *ngIf="currentPanelToDisplay.SECOND_PANELIST_NAME"> <b>Second Panelist : {{
        currentPanelToDisplay.SECOND_PANELIST_NAME }} </b> </p>
  </div>
</p-dialog>

<p-dialog *ngIf="isDisplayCommentsAction" [(visible)]="isDisplayCommentsAction" [style]="{width: '30vw'}"
  [draggable]="false" [modal]="true">
  <p-header> Add Comments for {{currentCandidateEmail}} </p-header>
  <div class="p-fluid">
    <form [formGroup]="commentActionForm" (ngSubmit)="onCommentActionSubmit()">
      <div class="p-field">
        <textarea rows="5" cols="30" pInputTextarea [autoResize]="true" id="comment" formControlName="comment"
          style="min-height: 36px; white-space: pre-wrap;" [maxlength]="textAreaLimit">
              </textarea>
        <div [ngStyle]="{color: 'red', 'margin-top': '5px'}"
          *ngIf="commentActionForm.controls['comment'].hasError('maxlength')">
          Maximum character limit reached.
        </div>
      </div>

      <div class="p-field">
        <button pButton type="submit" label="Submit" [disabled]="!commentActionForm.valid" [style]="{width: '5vw'}">
        </button>
      </div>
    </form>
  </div>

</p-dialog>

<p-dialog *ngIf="isScheduleHEAction" [(visible)]="isScheduleHEAction" [style]="{width: '50vw', height:'75%'}"
  [draggable]="false" [modal]="true">
  <p-header> Schedule Hacker Earth </p-header>

  <div class="p-fluid">
    <form [formGroup]="scheduleHeActionForm" (ngSubmit)="onHeActionSubmit()">
      <div header="Advanced Card" subheader="Card Subheader" style="width: '360px'; margin:2%;">
        <p-table [value]="[{}]">
          <ng-template pTemplate="body">
            <tr>
              <td><b>Select Date and Time</b></td>
              <td>
                <p-calendar formControlName="fromDate" hourFormat="12" [(ngModel)]="timeslot" [showIcon]="true"
                 [defaultDate]="timeslot" [minDate]="minimumDate" [showTime]="true" [ngStyle]="{
                  'zoom': '90%',
                  'display': 'inline-block',
                  'width': '49%',
                  'overflow': 'unset',
                  'position': 'absolute',
                  'top': '0%'
                }"></p-calendar>
              </td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ currentCandidateToDisplay.NAME }}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{{ currentCandidateToDisplay.PHONE }}</td>

            </tr>
            <tr>
              <td>Email</td>
              <td>{{ currentCandidateToDisplay.EMAIL }}</td>
            </tr>
            <tr>
              <td>Total Score</td>
              <td>{{currentCandidateToDisplay.TOTALSCORE }}</td>

            </tr>
            <tr>
              <td>Panel Id</td>
              <td>{{currentPanelToDisplay.ID }}</td>

            </tr>
            <tr>
              <td>First Panel</td>
              <td>{{currentPanelToDisplay.FIRST_PANELIST_NAME }}</td>

            </tr>
            <tr>
              <td>Second Panel</td>
              <td>{{currentPanelToDisplay.SECOND_PANELIST_NAME }}</td>

            </tr>

          </ng-template>
        </p-table>
      </div>
      <div class="p-field">
        <button pButton type="submit" label="Submit" [disabled]="!scheduleHeActionForm.valid" [style]="{width: '5vw'}"
          class="submit-button-form">
        </button>
      </div>
    </form>
  </div>

</p-dialog>

<p-dialog header="Action" *ngIf="isDisplayPanelUpdateAction" [(visible)]="isDisplayPanelUpdateAction"
  [style]="{width: '30vw'}" [draggable]="false" [modal]="true">
  <div class="p-fluid">
    <form [formGroup]="panelActionForm" (ngSubmit)="onPanelActionSubmit()">

      <div class="p-field">
        <p-dropdown formControlName="panel" [options]="interviewPanelList" [showClear]="true"
          placeholder="Select Panelist" [filter]="true" [filterBy]="'label'" appendTo="body">
        </p-dropdown>
      </div>

      <div class="p-field">
        <button pButton type="submit" label="Submit" [disabled]="!panelActionForm.valid" [style]="{width: '5vw'}">
        </button>
      </div>
    </form>
  </div>

</p-dialog>

<p-confirmDialog></p-confirmDialog>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { ConfirmationService, MessageService, ConfirmEventType } from 'primeng/api';

import { Subscription } from 'rxjs';
import * as FileSaver from 'file-saver';
import { ClipboardService } from 'ngx-clipboard';

import { CbApiService } from 'src/app/shared/cbapi.service';
import {
  CandidateDetails,
  DrivePanelList,
  GetDrivePanelListResponse,
  GetStaticDataResponse,
  GetCandidatesListResponse,
  InterviewPanelDetails,
  PipelineInterviewActionsDict,
  CandidateInfoToDisplay,
  CommentDetailToDisplay,
  GetCandidateDetailsResponse,
  AddInterviewRequest,
  ApiResponse,
  DrivePanelDetails,
  UpdateCandidateStatusRequest
} from 'src/app/shared/model';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { UserService } from 'src/app/shared/user.service';

@Component({
  selector: 'app-drive-pipeline',
  templateUrl: './drive-pipeline.component.html',
  styleUrls: ['./drive-pipeline.component.scss']
})
export class DrivePipelineComponent implements OnInit, OnDestroy {

  pipelineActionForm!: UntypedFormGroup;
  commentActionForm!: UntypedFormGroup;
  scheduleHeActionForm!: UntypedFormGroup;
  panelActionForm!: UntypedFormGroup;

  getPanelSub!: Subscription;
  addInterviewSub!: Subscription;
  updateCandidateSub!: Subscription;

  driveId: string = ''
  currentCandidateStatus: string = ''
  currentCandidateInterviewType: string = ''
  currentCandidateEmail: string = ''
  currentPanelToDisplay!: InterviewPanelDetails;

  textAreaLimit: number = 0;

  isDisplayPanelList: boolean = false;
  isDisplayPipelineAction: boolean = false;
  isBulkAssignment: boolean = false;
  isDisplayCommentsAction: boolean = false;
  isDisplayPanelInfo: boolean = false;
  isScheduleHEAction: boolean = false;
  timeslot = new Date();
  minimumDate = new Date();

  cols = [
    {
      field: 'serialNumber',
      header: '#'
    },
    {
      field: 'NAME',
      header: 'Name'
    },
    {
      field: 'EMAIL',
      header: 'Email'
    },
    {
      field: 'PHONE',
      header: 'Phone'
    },
    {
      field: 'RESUME',
      header: 'Links'
    },
    {
      field: 'CURRENT_PANEL',
      header: 'Panel'
    },
    {
      field: 'COLLEGE',
      header: 'College'
    },
    {
      field: 'actions',
      header: 'Actions'
    }
  ];

  allowedActionlist: SelectItem[] = [];
  candidatesList: CandidateDetails[] = [];
  candidateStatusList: string[] = [];
  pipelineInterviewActionsList: PipelineInterviewActionsDict = {};
  interviewPanelDetails: InterviewPanelDetails[] = [];
  masterDrivePanelList!: DrivePanelList;

  getStaticDataSub!: Subscription;
  getCandidateListSub!: Subscription;
  getCandidateSub!: Subscription;

  isDisplayCandidateInfo: boolean = false;
  isDisplayPanelUpdateAction: boolean = false;
  interviewPanelList: SelectItem[] = [];
  currentCandidateToDisplay!: CandidateInfoToDisplay;

  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private cbApiService: CbApiService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    public userService: UserService,
    private clipboardService: ClipboardService,private confirmationService: ConfirmationService) {
      var today=new Date()
    this.timeslot.setHours(today.getHours());
    this.timeslot.setMinutes(today.getMinutes());
    this.timeslot.setSeconds(today.getSeconds());
  }

  ngOnInit(): void {
    this.spinnerService.showSpinner();
    this.getDriveId();
    this.getPipelineData();
    this.getDrivePanelList();
  }

  initPipelineActionForm(): void {
    this.pipelineActionForm = this.fb.group({
      action: [
        '',
        [Validators.required]
      ],
      panel: [
        '',
        []
      ],
      comment: [
        '',
        [Validators.required, Validators.maxLength(this.textAreaLimit - 1)]
      ]
    });
  }

  getDriveId(): void {
    this.route.parent?.params.subscribe(params => {
      this.driveId = params['driveId'];
    });
  }

  displayaddComments(candidateEmail: string = '', type: string = ''): void {
    this.initCommentActionForm()
    this.currentCandidateEmail = candidateEmail
    this.isDisplayCommentsAction = true
  }

  initCommentActionForm(): void {
    this.commentActionForm = this.fb.group({
      comment: [
        '',
        [Validators.required, Validators.maxLength(this.textAreaLimit - 1)]
      ]
    });

  }

  getDrivePanelList(): void {
    this.getPanelSub = this.cbApiService.getDrivePanelList(this.driveId).subscribe({
      next: (data: any) => {
        this.mapDrivePanelList(data);
        this.spinnerService.hideSpinner();
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch panel list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  mapDrivePanelList(data: GetDrivePanelListResponse): void {
    if (data.success) {
      this.masterDrivePanelList = data.body as DrivePanelList;
      this.interviewPanelDetails = []

      if (this.masterDrivePanelList.INTERVIEW1_PANEL_LIST) {
        this.masterDrivePanelList.INTERVIEW1_PANEL_LIST.forEach((panel) => {
          this.interviewPanelDetails?.push({
            ID: panel.TYPE,
            INTERVIEW_TYPE: "INT_1",
            FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL,
            SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL,
          })
        }
        );
      }

      if (this.masterDrivePanelList.INTERVIEW2_PANEL_LIST) {
        this.masterDrivePanelList.INTERVIEW2_PANEL_LIST.forEach((panel) => {
          this.interviewPanelDetails?.push({
            ID: panel.TYPE,
            INTERVIEW_TYPE: "INT_2",
            FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL,
            SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL
          })
        }
        );
      }

      if (this.masterDrivePanelList.INTERVIEWM_PANEL_LIST) {
        this.masterDrivePanelList.INTERVIEWM_PANEL_LIST.forEach((panel) => {
          this.interviewPanelDetails?.push({
            ID: panel.TYPE,
            INTERVIEW_TYPE: "INT_M",
            FIRST_PANELIST_NAME: panel.INTERVIEWER1_EMAIL,
            SECOND_PANELIST_NAME: panel.INTERVIEWER2_EMAIL,
          })
        }
        );
      }
    }
  }

  getPipelineData(): void {
    this.spinnerService.showSpinner()
    this.getStaticDataSub = this.cbApiService.getStaticData()
      .subscribe({
        next: (data: GetStaticDataResponse) => this.handleStaticResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.toastService.showMessage('Failed to fetch static data', 'error');
        }
      });
  }

  handleStaticResponse(data: GetStaticDataResponse): void {
    if (data?.success) {
      this.candidateStatusList = Object.keys(data.body!.PIPELINE_INTERVIEW_ACTIONS_DICT);
      this.pipelineInterviewActionsList = data.body!.PIPELINE_INTERVIEW_ACTIONS_DICT;
      this.textAreaLimit = data.body?.TEXT_AREA_LIMIT as number;
    }
    this.spinnerService.hideSpinner();
  }

  displayPipelineActionDialog(status: string, candidateEmail: string = ''): void {
    this.initPipelineActionForm()
    this.isDisplayPanelList = false;
    this.allowedActionlist = []
    let allowedActions = this.pipelineInterviewActionsList[status]

    allowedActions.forEach(action => {
      this.allowedActionlist.push({ label: action, value: action })
    })

    this.currentCandidateStatus = status
    this.currentCandidateEmail = candidateEmail
    this.isDisplayPipelineAction = true;

    if (candidateEmail) {
      this.isBulkAssignment = false;
    }
    else {
      this.isBulkAssignment = true;
    }
  }

  getCandidatesByStatus(status: string): CandidateDetails[] | any {
    this.getCandidateListSub = this.cbApiService.getCandidatesList(this.driveId, status).subscribe({
      next: (data: GetCandidatesListResponse) => {
        this.candidatesList = data.body;
        this.spinnerService.hideSpinner();
        this.processForHeCancelOrUpdate(status)
        return this.candidatesList;
      },
      error: () => {
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  processForHeCancelOrUpdate(status: string) {
    for (let candidate of this.candidatesList) {
      let interviewid = this.getInterviewIdFromStatus(status, candidate)
      candidate.SHOW_CANCEL = (interviewid != undefined && interviewid != "")
    }
  }

  getInterviewIdFromStatus(status: string, candidate: CandidateDetails) {
    if (status == 'INT_1_SCHD') {
      return candidate.INT_1_SCHD_HE_INTERVIEW_ID;
    }
    if (status == 'INT_2_SCHD') {
      return candidate.INT_2_SCHD_HE_INTERVIEW_ID
    }
    if (status == 'INT_M_SCHD') {
      return candidate.INT_M_SCHD_HE_INTERVIEW_ID;
    }
    return
  }

  displayCandidateInfo(candidate_email: string) {
    this.spinnerService.showSpinner();
    this.getCandidateDetailsToDisplay(candidate_email);
  }

  scheduleInterview(candidate_email: string) {
    this.spinnerService.showSpinner()
    this.currentCandidateEmail = candidate_email
    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidate_email).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        this.mapCandidateAndPanelDetails(data);
        this.initScheduleHeForm()
        this.spinnerService.hideSpinner();
        this.isScheduleHEAction = true;
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates info', 'error');
      }
    });
  }

  confirmCancelation(event: Event, candidate: CandidateDetails,) {
    let message='Are you sure that you want to cancel interview for '+candidate.EMAIL+'?'
    this.confirmationService.confirm({
        target: event.target as EventTarget,
        message: message,
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"none",
        accept: () => {
            this.cancelInterview(candidate)
        }
    });
}

  cancelInterview(candidate: CandidateDetails,) {
    this.spinnerService.showSpinner();
    let interviewId = this.getInterviewIdFromStatus(candidate.STATUS, candidate)
    this.cbApiService.cancelInterview(this.driveId, candidate.CANDIDATE_ID, interviewId!).subscribe({
      next: (data: ApiResponse) => {
        this.isScheduleHEAction = false;
        this.spinnerService.hideSpinner();
        this.refreshInterviewDetails(this.currentCandidateStatus);

      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates info', 'error');
      }
    });
  }

  initScheduleHeForm(): void {

    this.scheduleHeActionForm = this.fb.group({
      fromDate: [
        '',
        [Validators.required, Validators.maxLength(this.textAreaLimit - 1)]
      ]
    })
  }

  onHeActionSubmit(): void {
    let timeslot = this.scheduleHeActionForm.value.fromDate.toISOString()
    this.spinnerService.showSpinner()
    let response = this.cbApiService.scheduleHeInterview(this.driveId, this.currentCandidateEmail, { "start_time": timeslot }).subscribe({
      next: (data: ApiResponse) => {
        if (data?.success) {
          this.toastService.showMessage('Successfully scheduled the interview.', 'success');
        } else {
          this.toastService.showMessage('Failed to schedule interview', 'error');
        }
        this.isScheduleHEAction = false;
        this.spinnerService.hideSpinner();
        this.refreshInterviewDetails(this.currentCandidateStatus);
      },
      error: () => {
        this.isScheduleHEAction = false;
        this.toastService.showMessage('Failed to schedule candidate', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  displayPanelInfo(panel_id: string) {
    this.spinnerService.showSpinner();
    let panelToDisplay = this.interviewPanelDetails.find(panel => panel.ID == panel_id);
    if (panelToDisplay) {
      this.currentPanelToDisplay = panelToDisplay;
    }

    this.isDisplayPanelInfo = true;
    this.spinnerService.hideSpinner();
  }

  getCandidateDetails(candidateEmail: string): GetCandidateDetailsResponse | any {
    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidateEmail).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        this.spinnerService.hideSpinner();
        return data;
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
      }
    });
  }

  getCandidateDetailsToDisplay(candidateEmail: string): GetCandidateDetailsResponse | any {
    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidateEmail).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        this.mapCandidateDetails(data);
        this.spinnerService.hideSpinner();
        this.isDisplayCandidateInfo = true
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
      }
    });
  }

  mapCandidateAndPanelDetails(data: GetCandidateDetailsResponse) {
    this.mapCandidateDetails(data);
    let panelToDisplay = this.interviewPanelDetails.find(panel => panel.ID == this.currentCandidateToDisplay.CURRENT_PANEL);
    if (panelToDisplay) {
      this.currentPanelToDisplay = panelToDisplay;
    }
  }
  
  mapCandidateDetails(data: GetCandidateDetailsResponse) {
    if (data) {
      let candidate = data.body;

      const candidateInfo: CandidateInfoToDisplay = {
        PHONE: candidate.PHONE,
        NAME: candidate.NAME,
        EMAIL: candidate.EMAIL,
        ROLLNO: candidate.ROLLNO,
        RESUME: candidate.RESUME,
        APTISCORE: candidate.APTISCORE,
        TOTALSCORE: candidate.TOTALSCORE,
        DEGREE: candidate.DEGREE,
        BRANCH: candidate.BRANCH,
        STATUS: candidate.STATUS,
        COLLEGE: candidate.COLLEGE,
        DRIVE_ID: candidate.DRIVE_ID,
        CANDIDATE_ID: candidate.CANDIDATE_ID,
        CURRENT_PANEL: candidate.CURRENT_PANEL,
        PROGSCORE:candidate.PROGSCORE,
        COMMENTS: []
      }
      candidate.COMMENTS.forEach(comment => {
        let splitted_comment_detail = comment.COMMENT.split(":");
        const comment_to_display: CommentDetailToDisplay = {
          COMMENT_DATE: splitted_comment_detail[0] + ':' + splitted_comment_detail[1] + ':' + splitted_comment_detail[2],
          COMMENT_BY: splitted_comment_detail[3].trim(),
          COMMENT_MESSAGE: splitted_comment_detail.slice(4).join(':').trim()
        }
        candidateInfo.COMMENTS!.push(comment_to_display);
      })
      this.currentCandidateToDisplay = candidateInfo;
    }
  }

  onTabOpen(event: any) {
    this.spinnerService.showSpinner()
    const status = this.candidateStatusList[event.index];
    this.currentCandidateStatus = status;
    this.getCandidatesByStatus(status);
  }

  getPanelsByInterviewType(): SelectItem[] {
    let splitted_action = this.pipelineActionForm.value.action.split('_')
    let type = `${splitted_action[0]}_${splitted_action[1]}`
    let interviewPanelListByType: SelectItem[] = [];

    this.interviewPanelDetails.filter(panel =>
      panel.INTERVIEW_TYPE == type).forEach(panel => {
        let firstPanelistName = panel.FIRST_PANELIST_NAME?.split('@')
        let label = `${panel.ID} - ${firstPanelistName![0]}`

        if (panel.SECOND_PANELIST_NAME) {
          let secondPanelistName = panel.SECOND_PANELIST_NAME?.split('@')
          label = `${label} & ${secondPanelistName![0]}`
        }

        interviewPanelListByType.push({
          label: label,
          value: panel.ID
        })
      });
    return interviewPanelListByType;
  }

  onActionSelect(event: any) {
    if (event.value.endsWith('SCHD') && !this.isBulkAssignment) {
      this.isDisplayPanelList = true;
    }
    else {
      this.isDisplayPanelList = false;
    }
  }

  getPanelByPanelId(panelId: string, interviewType: string) {
    if (interviewType == 'INT_1') {
      return this.masterDrivePanelList.INTERVIEW1_PANEL_LIST?.find(panel => panel.TYPE == panelId);
    }
    else if (interviewType == 'INT_2') {
      return this.masterDrivePanelList.INTERVIEW2_PANEL_LIST?.find(panel => panel.TYPE == panelId);
    }
    else if (interviewType == 'INT_M') {
      return this.masterDrivePanelList.INTERVIEWM_PANEL_LIST?.find(panel => panel.TYPE == panelId);
    }
    return null
  }

  onPipelineActionSubmit(): void {
    this.spinnerService.showSpinner();
    let action = this.pipelineActionForm.value.action;

    if (action.endsWith('SCHD')) {
      let interviewType: string = ''

      let splitted_action = action.split('_')
      interviewType = `${splitted_action[0]}_${splitted_action[1]}`

      let panel = this.pipelineActionForm.value.panel;

      if (panel) {
        this.scheduleCandidateInterview(interviewType, panel, this.currentCandidateEmail);
        return;
      }
      else {
        this.scheduleBulkInterviews(interviewType, this.currentCandidateStatus);
        return;
      }
    }
    else {
      this.updateCandidateStatus(action, this.pipelineActionForm.value.comment, this.currentCandidateEmail);
    }
  }

  onCommentActionSubmit(): void {
    this.spinnerService.showSpinner();
    var comments = this.commentActionForm.value.comment
    var updateCandidatePayload = {
      "COMMENT": comments
    }
    this.updateCandidateSub = this.cbApiService.addComments
      (updateCandidatePayload, this.driveId, this.currentCandidateEmail)
      .subscribe({
        next: (data: ApiResponse) => this.handleUpdateCandidateResponse(data),
        error: () => {
          this.spinnerService.hideSpinner();
          this.isDisplayCommentsAction = false;
          this.toastService.showMessage('Failed to update candidate', 'error');
        }
      });
  }

  handleUpdateCandidateResponse(data: ApiResponse): void {
    if (data?.success) {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Successfully updated the candidate', 'success');

    } else {
      this.spinnerService.hideSpinner();
      this.toastService.showMessage('Failed to update candidate', 'error');
    }
    this.isDisplayCommentsAction = false;
  }

  scheduleBulkInterviews(interviewType: string, status: string): void {
    let panelList: DrivePanelDetails[] = [];

    if (interviewType == 'INT_1') {
      panelList = this.masterDrivePanelList.INTERVIEW1_PANEL_LIST!
    }
    else if (interviewType == 'INT_2') {
      panelList = this.masterDrivePanelList.INTERVIEW2_PANEL_LIST!
    }
    else if (interviewType == 'INT_M') {
      panelList = this.masterDrivePanelList.INTERVIEWM_PANEL_LIST!
    }

    this.getCandidateListSub = this.cbApiService.getCandidatesList(this.driveId, status).subscribe({
      next: (data: GetCandidatesListResponse) => {
        let candidateList = data.body;

        let addInterviewRequest: AddInterviewRequest = {
          CANDIDATE_LIST: candidateList,
          PANEL_LIST: panelList,
          INTERVIEW_TYPE: interviewType,
          COMMENTS: this.pipelineActionForm.value.comment
        }
        this.addInterview(addInterviewRequest)
      },
      error: () => {
        this.toastService.showMessage('Failed to schedule bulk interviews.', 'error');
        this.spinnerService.hideSpinner();
      }
    });
  }

  scheduleCandidateInterview(interviewType: string, panel: string, candidateEmail: string): void {
    let panelList: DrivePanelDetails[] = [];
    let panelDetail = this.getPanelByPanelId(panel, interviewType)

    if (panelDetail) {
      panelList.push(panelDetail!)
    }

    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, candidateEmail).subscribe({
      next: (data: GetCandidateDetailsResponse) => {
        let candidateList = [data.body];

        let addInterviewRequest: AddInterviewRequest = {
          CANDIDATE_LIST: candidateList,
          PANEL_LIST: panelList,
          INTERVIEW_TYPE: interviewType,
          COMMENTS: this.pipelineActionForm.value.comment
        }

        this.addInterview(addInterviewRequest);
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
      }
    });
  }

  addInterview(addInterviewRequest: AddInterviewRequest): void {
    this.addInterviewSub = this.cbApiService.addInterview(addInterviewRequest, this.driveId)
      .subscribe({
        next: (data: ApiResponse) => {
          if (data?.success) {
            this.toastService.showMessage('Successfully scheduled the interview.', 'success');
          } else {
            this.toastService.showMessage('Failed to schedule interview', 'error');
          }
          this.isDisplayPipelineAction = false;
          this.spinnerService.hideSpinner();
          this.refreshInterviewDetails(this.currentCandidateStatus);
        },
        error: () => {
          this.isDisplayPipelineAction = false;
          this.toastService.showMessage('Failed to add candidate', 'error');
          this.spinnerService.hideSpinner();
        }
      });
  }

  updateCandidateStatus(updated_status: string, comment: string, email: string): void {
    let updateCandidateStatusRequest: UpdateCandidateStatusRequest = {
      STATUS: updated_status,
      COMMENT: comment
    }

    this.updateCandidateSub = this.cbApiService.updateCandidateStatus(updateCandidateStatusRequest, this.driveId, email).subscribe({
      next: (data: ApiResponse) => {
        if (data?.success) {
          this.toastService.showMessage('Successfully updated the status.', 'success');
        } else {
          this.toastService.showMessage('Failed to update status.', 'error');
        }
        this.isDisplayPipelineAction = false;
        this.spinnerService.hideSpinner();
        this.refreshInterviewDetails(this.currentCandidateStatus);
      },
      error: () => {
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
      }
    });
  }

  generateCandidatePayload(candidate: GetCandidateDetailsResponse): CandidateDetails {
    const candidatePayload = {
      COLLEGE: candidate.body.COLLEGE,
      PHONE: candidate.body.PHONE,
      GRADYEAR: candidate.body.GRADYEAR,
      NAME: candidate.body.NAME,
      PROGSCORE: candidate.body.PROGSCORE,
      APTISCORE: candidate.body.APTISCORE,
      TOTALSCORE: candidate.body.TOTALSCORE,
      DEGREE: candidate.body.DEGREE,
      BRANCH: candidate.body.BRANCH,
      LOCATION: candidate.body.LOCATION,
      MAXSCORE: candidate.body.MAXSCORE,
      STATUS: candidate.body.STATUS,
      RESUME: candidate.body.RESUME,
      ROLLNO: candidate.body.ROLLNO,
      DRIVE_ID: this.driveId,
      CANDIDATE_ID: candidate.body.CANDIDATE_ID,
      EMAIL: candidate.body.EMAIL
    }

    return candidatePayload
  }

  exportExcel() {
    let headers = this.getHeadersForExcel();
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.candidatesList, { header: headers });
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, " candidates");
    });
  }

  getHeadersForExcel() {
    let headers = ['NAME', 'EMAIL', 'PHONE', 'RESUME', 'BRANCH', 'ROLLNO', 'COLLEGE', 'STATUS', 'MAXSCORE', 'GRADYEAR', 'PROGSCORE', 'COMMENT', 'LOCATION', 'DRIVE_ID', 'DEGREE', 'CANDIDATE_ID', 'TOTALSCORE', 'APTISCORE']
    return headers

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  refreshInterviewDetails(status: string = this.currentCandidateStatus): void {
    if (this.currentCandidateStatus) {
      this.spinnerService.showSpinner();
      this.getCandidatesByStatus(status);
    }
  }

  copyTextToClipboard(rowData: string): void {
    this.clipboardService.copyFromContent(rowData);
  }

  checkPanelUpdate(status: string): boolean {
    if (status.endsWith('SCHD')) {
      return true;
    }
    return false;
  }

  initPanelActionForm(currentPanel: string) {
    this.panelActionForm = this.fb.group({
      panel: [
        currentPanel
      ]
    });
  }

  displayUpdatePanel(status: string, candidateEmail: string, currentPanel: string): void {
    this.isDisplayPanelUpdateAction = true;
    this.interviewPanelList = this.getPanels(status);
    this.initPanelActionForm(currentPanel);
    this.currentCandidateEmail = candidateEmail;
  }

  addCandidateForPanelUpdate(panelList: DrivePanelDetails[], splitted_action: string[]) {
    this.getCandidateSub = this.cbApiService.getCandidateDetails(this.driveId, this.currentCandidateEmail)
      .subscribe({
        next: (data: GetCandidateDetailsResponse) => {  
          let candidateList = [data.body];

          let addInterviewRequest: AddInterviewRequest = {
            CANDIDATE_LIST: candidateList,
            PANEL_LIST: panelList,
            INTERVIEW_TYPE: `${splitted_action[0]}_${splitted_action[1]}`,
            COMMENTS: "Updating Panel"
          }

          this.addInterviewSub = this.cbApiService.addInterview(addInterviewRequest, this.driveId)
            .subscribe({
              next: (data: ApiResponse) => {
                if (data?.success) {
                  this.toastService.showMessage('Successfully scheduled the interview.', 'success');
                } else {
                  this.toastService.showMessage('Failed to schedule interview', 'error');
                }
                this.isDisplayPanelUpdateAction = false;
                this.refreshInterviewDetails(this.currentCandidateStatus);
              },
              error: () => {
                this.isDisplayPanelUpdateAction = false;
                this.toastService.showMessage('Failed to add candidate', 'error');
                this.spinnerService.hideSpinner();
              }
            });
          },
          error: () => {
            this.isDisplayPanelUpdateAction = false;
            this.spinnerService.hideSpinner();
            this.toastService.showMessage('Failed to fetch candidates list', 'error');
          }
        });
  }

  onPanelActionSubmit() {
    this.spinnerService.showSpinner();
    let panel = this.panelActionForm.value.panel;
    let splitted_action = this.currentCandidateStatus.split('_')
    let updated_status = `${splitted_action[0]}_${splitted_action[1]}_CANC`
    let updateCandidateStatusRequest: UpdateCandidateStatusRequest = {
      STATUS: updated_status,
      COMMENT: "Updating panel"
    }
    let panelDetail = this.getPanelByPanelId(panel, `${splitted_action[0]}_${splitted_action[1]}`);

    this.updateCandidateSub = this.cbApiService.updateCandidateStatus(updateCandidateStatusRequest, this.driveId, this.currentCandidateEmail)
    .subscribe({
      next: (data: ApiResponse) => {
        if (data?.success) {
          let panelList: DrivePanelDetails[] = [];
          if (panelDetail) {
            panelList.push(panelDetail!)
          }
          this.addCandidateForPanelUpdate(panelList, splitted_action);
        } else {
          this.toastService.showMessage('Failed to update status.', 'error');
        }
        this.isDisplayPanelUpdateAction = false;
      },
      error: () => {
        this.isDisplayPanelUpdateAction = false;
        this.spinnerService.hideSpinner();
        this.toastService.showMessage('Failed to fetch candidates list', 'error');
      }
    });
  }

  getPanels(type: string) {
    let splitted_action = type.split('_');
    type = `${splitted_action[0]}_${splitted_action[1]}`;
    this.interviewPanelList = [];

    this.interviewPanelDetails.filter(panel =>
      panel.INTERVIEW_TYPE == type).forEach(panel => {
        let firstPanelistName = panel.FIRST_PANELIST_NAME?.split('@')
        let label = `${panel.ID} - ${firstPanelistName![0]}`

        if (panel.SECOND_PANELIST_NAME) {
          let secondPanelistName = panel.SECOND_PANELIST_NAME?.split('@')
          label = `${label} & ${secondPanelistName![0]}`
        }

        this.interviewPanelList.push({
          label: label,
          value: panel.ID
        })
      });

    return this.interviewPanelList;
  }

  ngOnDestroy(): void {
    this.getStaticDataSub?.unsubscribe();
    this.getCandidateListSub?.unsubscribe();
    this.getCandidateSub?.unsubscribe();
    this.getPanelSub?.unsubscribe();
    this.addInterviewSub?.unsubscribe();
    this.updateCandidateSub?.unsubscribe();
  }
}

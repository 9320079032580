
<div *ngIf="!isAddComponentSelected">
  <p-messages [(value)]="messages" [enableService]="false"></p-messages>
  <div class="sidebar-component-header">
    <div class="component-title">
      Candidates List
    </div>
    <div class="component-search-bar" *ngIf="this.userService.IsAdmin()">
      <i class="pi pi-refresh" 
      style="color: #367B88; margin-right: 10px; margin-top: 40x; font-size: 17px; cursor: pointer;"
      (click)="getCandidatesList()"></i>
      <p-button 
        class="add-candidates-btn" 
        label="Add Candidates"
        (click)="enableAddCandidateComponent()">
      </p-button>
    </div>
  </div> 
  <div>
    <p-table 
      #candidates
      [columns]="cols" 
      [value]="tableData" 
      styleClass="p-datatable-gridlines" 
      [scrollable]="true" 
      scrollDirection="horizontal"
      [globalFilterFields]="['NAME', 'EMAIL', 'PHONE', 'STATUS']">
      <ng-template pTemplate="caption"> 
        <div style="text-align: right"> 
            <span class="p-input-icon-right">
              <i class="pi pi-search" style="color: #367B88"></i>
              <input type="text" pInputText  
              size="30" 
              placeholder="Search"
              (input)= "candidates.filterGlobal($any($event.target).value, 'contains')"  
              style="width:auto"  />
            </span>
        </div> 
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" style="min-width:150px">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <ng-container *ngIf="col.field === 'ACTIONS'">
              <div class="icon-container"  *ngIf="this.userService.IsAdmin()">
                <i *ngFor="let iconClass of rowData.ACTIONS; let i=index" (click)="actionClick(i, rowData)"
                  class="{{ iconClass }}" style="cursor: pointer;"></i>
              </div>
            </ng-container>
            <ng-container *ngIf="col.field == 'RESUME'">
              <ng-container *ngIf="rowData[col.field]; else noResume">
                <a style="color:blue" [href]="rowData[col.field]" target="_blank">Resume link</a>
              </ng-container>
              <ng-template #noResume>
                No resume
              </ng-template>
            </ng-container>
            <ng-container *ngIf="col.field !== 'ACTIONS' && col.field !== 'RESUME'">
              {{ rowData[col.field] }}
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]='cols.length'>No records found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<p-dialog header="Candidate Details" *ngIf="isDisplayCandidateInfo" [(visible)]="isDisplayCandidateInfo"
    [style]="{ width: '60vw', 'margin-left': '0px' }" [draggable]="false" [resizable]="false">
    <div header="Advanced Card" subheader="Card Subheader" style="width: '360px'; margin-left: '80px';">
        <p style="margin-bottom: 3px;"> <b>Name :</b> {{currentCandidateToDisplay.NAME}} </p>
        <p style="margin-bottom: 3px;"> <b>Phone :</b> {{currentCandidateToDisplay.PHONE}} </p>
        <p style="margin-bottom: 3px;"> <b>Email :</b> {{currentCandidateToDisplay.EMAIL}} </p>
        <p style="margin-bottom: 3px;"> <b>Resume : </b>
          <ng-container *ngIf="currentCandidateToDisplay.RESUME !== null; else noResume">
            <a style="color:blue" [href]="currentCandidateToDisplay.RESUME" target="_blank">Resume link</a>
          </ng-container>
          <ng-template #noResume>
            No resume
          </ng-template>
        </p>
        <p style="margin-bottom: 3px;"> <b>Total Score :</b> {{currentCandidateToDisplay.TOTALSCORE}} </p>
        <p style="margin-bottom: 3px;" *ngIf="currentCandidateToDisplay.APTISCORE"> <b>Total Aptitude score :</b>
            {{currentCandidateToDisplay.APTISCORE}} </p>
    </div>

    <p style="margin: 25px 0px 35px 0px;"> <b>Comments:</b> </p>

    <p-timeline [value]="currentCandidateToDisplay.COMMENTS!">
        <ng-template pTemplate="opposite" let-comment>
            <small class="p-text-secondary">{{comment.COMMENT_DATE}} by {{comment.COMMENT_BY}}</small>
        </ng-template>
        <ng-template pTemplate="content" let-comment>
            <pre style="margin-bottom: 15px; border-bottom: 1px solid #ddd; "><div style="white-space: pre-wrap;">{{comment.COMMENT_MESSAGE}}</div></pre>
        </ng-template>
    </p-timeline>
</p-dialog>

<div *ngIf="isAddComponentSelected">
  <i class="pi pi-times" (click)="disableAddCandidateComponent()" style="cursor: pointer;"></i>
  <div class="upload-candidate-header">
    UPLOAD CANDIDATE DETAILS
  </div>
  <div class="upload-candidate-options-content">
    <div class="bulk-upload-candidate-sub-content">  
      <div class ="bulk-upload-candidate-icon">
        <i class="pi pi-upload upload-icon"></i>
      </div>
      <div class="upload-file-content">
        <span id="upload-file-heading">Upload file</span>
        <br>
        <span id="upload-file-sub-heading">Please upload candidates details.</span>
      </div>
      <div class="upload-button-section">
        <p-fileUpload #bulkFileUpload mode="basic" chooseLabel="Upload" [customUpload]="true" (uploadHandler)="onFileSelect($event,bulkFileUpload)" [auto]="true"></p-fileUpload>
      </div>
    </div>
    <div class="divider-section">
      <p-divider layout="vertical"></p-divider>
    </div>
    <div class="add-candidate-options">
      <div class="add-candidate-icon-content-option" >
        <i class="pi pi-user-plus add-user-icon"></i>
      </div>
      <div class="add-candidate-content">
        <span id="add-candidate-heading">Add by using email address.</span>
        <br>
        <span id="add-candidate-sub-heading">Recommended if adding candidates less than 5.</span>
      </div>
      <div class="add-button-section">
        <p-button label="Add" (click)="addCandidate()"></p-button>
      </div>
    </div>
  </div>  
</div>

<p-dialog header="Add Candidate" [(visible)]="isShowAddCandidateForm" [style]="{width: '35vw'}" [draggable]="false">
  <div class="p-fluid">
      <form [formGroup]="addCandidateForm" (ngSubmit)="onAddCandidateFormSubmit()">
        <div class="p-field">
          <label for="name">Name</label>
          <input
            id="name"
            type="text"
            pInputText
            formControlName="name"/>
        </div>
        <div class="p-field">
          <label for="email">Email</label>
          <input
            id="email"
            type="email"
            pInputText
            formControlName="email"/>
        </div>
        <div class="p-field">
          <label for="phone">Phone</label>
          <input
            id="phone"
            type="text"
            pInputText
            formControlName="phone"
          />
        </div>
        <div class="p-field">
          <label for="Resume">Resume</label>
          <input
            id="resume"
            type="text"
            pInputText
            formControlName="resume"/>
        </div>
        <div class="p-field">
          <label for="degree">Degree</label>
          <input
            id="degree"
            type="text"
            pInputText
            formControlName="degree"/>
        </div>
        <div class="p-field">
          <label for="branch">Branch</label>
          <input
            id="branch"
            type="text"
            pInputText
            formControlName="branch"/>
        </div>
        <div class="p-field">
          <label for="rollnumber">Roll Number</label>
          <input
            id="rollnumber"
            type="text"
            pInputText
            formControlName="rollnumber"/>
        </div>
        <div class="p-field">
          <label for="gradyear">Graduation Year</label>
          <input
            id="gradyear"
            type="text"
            pInputText
            formControlName="gradyear"/>
        </div>
        <div class="p-field">
          <label for="location">Location</label>
          <input
            id="location"
            type="text"
            pInputText
            formControlName="location"/>
        </div>
        <div class="p-field">
          <label for="college">College</label>
          <input
            id="college"
            type="text"
            pInputText
            formControlName="college"/>
        </div>
        <div class="p-field">
          <label for="totalscore">Total Score</label>
          <input
            id="totalscore"
            type="text"
            pInputText
            formControlName="totalscore"/>
        </div>
        <div class="p-field">
          <label for="maxscore">Maximum Score</label>
          <input
            id="maxscore"
            type="text"
            pInputText
            formControlName="maxscore"/>
        </div>
        <div class="p-field">
          <label for="progscore">Program Score</label>
          <input
            id="progscore"
            type="text"
            pInputText
            formControlName="progscore"/>
        </div>
        <div class="p-field">
          <label for="aptiscore">Aptitude Score</label>
          <input
            id="aptiscore"
            type="text"
            pInputText
            formControlName="aptiscore"/>
        </div>
        <div class="p-field">
          <label for="comment">Comment</label>
          <textarea 
            rows="5" 
            cols="30" 
            pInputTextarea [autoResize]="true"
            id="comment"
            formControlName="comment"
            style="min-height: 36px; white-space: pre-wrap;">
          </textarea>
        </div>
        <button 
          pButton 
          type="submit" 
          label="Submit" 
          [disabled]="!addCandidateForm.valid" 
          [style]="{width: '5vw'}">
        </button>
      </form>
    </div>      
</p-dialog>

<p-dialog header="Delete Candidate" [(visible)]="isShowDeleteCandidate" [style]="{width: '30vw'}" [draggable]="false">
  <div>
    <p style="margin-bottom: 20px;">Are you sure you want to delete this candidate with email address: {{ emailToDelete }}?</p>
    <p-button 
      [ngStyle]="{ 'margin-right': '10px' }"
      type="button" 
      label="Confirm"
      (click)="deleteCandidate()">
    </p-button>
    <p-button class="p-cancel-btn"
      type="button" 
      label="Cancel" 
      (click)="hideDeleteCandidateDialog()">
    </p-button>
  </div>      
</p-dialog>

<p-dialog header="Update Candidate" *ngIf="isShowCandidateUpdateForm" [(visible)]="isShowCandidateUpdateForm" [style]="{width: '35vw'}" [draggable]="false">
  <div class="p-fluid">
      <form [formGroup]="updateCandidateForm" (ngSubmit)="onUpdateCandidateFormSubmit()">
        <div class="p-field">
          <label for="college">College</label>
          <input id="college" type="text" pInputText formControlName="college"/>
        </div>
        <div class="p-field">
          <label for="email">Email</label>
          <input id="email" type="email" pInputText formControlName="email" readonly [style]="{background: '#dddddd'}"/>
        </div>
        <div class="p-field">
          <label for="status">Status</label>
          <input id="status" type="text" pInputText formControlName="status" readonly [style]="{background: '#dddddd'}"/>
        </div>
        <div class="p-field">
          <label for="name">Name</label>
          <input id="name" type="text" pInputText formControlName="name"/>
        </div>
        <div class="p-field">
          <label for="resume">Resume</label>
          <input id="resume" type="text" pInputText formControlName="resume"/>
        </div>
        <div class="p-field">
          <label for="phone">Phone</label>
          <input id="phone" type="text" pInputText formControlName="phone"/>
        </div>
        <div class="p-field">
          <label for="rollnumber">Roll number</label>
          <input id="rollnumber" type="text" pInputText formControlName="rollnumber"/>
        </div>
        <div class="p-field">
          <label for="branch">Branch</label>
          <input id="branch" type="text" pInputText formControlName="branch"/>
        </div>
        <div class="p-field">
          <label for="degree">Degree</label>
          <input id="degree" type="text" pInputText formControlName="degree"/>
        </div>
        <div class="p-field">
          <label for="gradyear">Graduation Year</label>
          <input id="gradyear" type="text" pInputText formControlName="gradyear"/>
        </div>
        <div class="p-field">
          <label for="totalscore">Total Score</label>
          <input id="totalscore" type="text" pInputText formControlName="totalscore"/>
        </div>
        <div class="p-field">
          <label for="maxscore">Maximum Score</label>
          <input id="maxscore" type="text" pInputText formControlName="maxscore"/>
        </div>
        <div class="p-field">
          <label for="aptiscore">Aptitude Score</label>
          <input id="aptiscore" type="text" pInputText formControlName="aptiscore"/>
        </div>
        <div class="p-field">
          <label for="progscore">Programming Score</label>
          <input id="progscore" type="text" pInputText formControlName="progscore"/>
        </div>
        <div class="p-field">
          <label for="location">Location</label>
          <input id="location" type="text" pInputText formControlName="location"/>
        </div>
        <div class="p-field">
          <label for="comment">Comment</label>
          <textarea 
            rows="5" 
            cols="30" 
            pInputTextarea [autoResize]="true"
            id="comment"
            formControlName="comment"
            style="min-height: 36px;">
          </textarea>
        </div>
        <p-button  
          type="submit" 
          label="Submit" 
          [disabled]="!updateCandidateForm.valid" 
          [style]="{width: '5vw'}">
        </p-button>
      </form>
    </div>      
</p-dialog>